<template>
    <div class="content1">
          <div @click="mineMessage" class="messageTitle1">
            <img class="messageIcon" src="../../assets/message.png" />
            <div>联系客服</div>
          </div>
          <div class="steps1">
            <div>
              <div class="boxcolor">1</div>
              <div class="linecolor"></div>
            </div>
            <div class="neth">
                <div class="title1">支付成功<img class="success2" src="../../assets/successIcon2.png"/></div>
                <div>
                  <div>{{tableData.pay_type==2?'微信支付':'线下支付'}}</div><div v-if="tableData.pay_type!=1">支付金额{{tableData.pay_amount}}</div>
                </div>
            </div>
          </div>
          <div class="steps1">
            <div>
              <div class="boxcolor">2</div>
              <div class="linecolor"></div>
            </div>
            <div class="neth">
              <div class="title1" style="display:flex">平台受理<img v-if="tableData.audit_state==2" class="success2" src="../../assets/successIcon2.png"/><img v-if="tableData.audit_state==3" class="success2" src="../../assets/errorIcon2.png"/><div v-if="tableData.info_state!=2" @click="submit(1)" class="title_text"  style="">点击修改>></div></div>
              <div>{{tableData.create_time}} 工会报名申请,正在审核中</div>
              <div>将在1~2个工作日完成审核</div>
              <div  v-if="tableData.audit_state==2">{{tableData.update_time}}</div>
              <div v-if="tableData.audit_state==2" class="title1">审核成功,成为会员</div>
              
            </div>
            
          </div>
          <div v-if="tableData.audit_state!=3" class="steps1">
            <div>
              <div v-if="tableData.audit_state<2" class="boxcolor" style="color:rgba(0,0,0,.5);border:1px solid rgba(0,0,0,.5)">3</div>
              <div v-else class="boxcolor">3</div>
              <div v-if="tableData.express_state==2" class="linecolor"></div>
              <div v-else style="border:.5px dashed rgba(0,0,0,.5)" class="linecolor"></div>
            </div>
            <div class="neth">
              <div v-if="tableData.audit_state==2" class="title1">物料制作<img v-if="tableData.express_state==2" class="success2" src="../../assets/successIcon2.png"/><img v-if="tableData.audit_state==3" class="success2" src="../../assets/errorIcon2.png"/></div>
              <div v-else class="title1" style="color:rgba(0,0,0,.5)">物料制作<img v-if="tableData.express_state==2" class="success2" src="../../assets/successIcon2.png"/><img v-if="tableData.audit_state==3" class="success2" src="../../assets/errorIcon2.png"/></div>
              <div>{{tableData.info_at}}</div>
              <div v-if="tableData.audit_state==2" class="card">会员证制作中，请耐心等候</div>
              <div v-if="tableData.express_state==2">{{tableData.express_at}}</div>
              <div v-if="tableData.express_state==2" class="card" style="color:#FF5E5E">会员证已制作完成</div>
            </div>
            
          </div>
          <div class="steps1">
            <div>
              <div v-if="tableData.express_state==2||tableData.audit_state==3" class="boxcolor">{{3>tableData.audit_state?4:3}}</div>
              <div v-else class="boxcolor" style="color:rgba(0,0,0,.5);border:1px solid rgba(0,0,0,.5)">{{3>tableData.audit_state?4:3}}</div>
              
            </div>
            <div  class="neth">
              <div class="title1" v-if="tableData.express_state==2||tableData.audit_state==3" >{{tableData.audit_state==3?'已结束':'已完成'}}<img v-if="tableData.express_state==2" class="success2" src="../../assets/successIcon2.png"/><img v-if="tableData.audit_state==3" class="success2" src="../../assets/errorIcon2.png"/></div>
              <div class="title1" v-else style="color:rgba(0,0,0,.5)">{{tableData.audit_state==3?'已结束':'已完成'}}<img v-if="tableData.express_state==2" class="success2" src="../../assets/successIcon2.png"/><img v-if="tableData.audit_state==3" class="success2" src="../../assets/errorIcon2.png"/></div>
              <!-- <div class="title1" style="color:#FF5E5E"  v-if="tableData.audit_state==2">{{tableData.audit_state_text}}</div> -->
              <div>{{tableData.express_at}}</div>
              <div v-if="tableData.express_state==2" style="color:#FF5E5E">会员证已寄出，请耐心等候</div>
              <div  v-if="tableData.audit_state==3">审核失败</div>
              <div  v-if="tableData.audit_state==3">原因：<span style="color:#FF5E5E">{{tableData.audit_remark[0]}}</span></div>
              <div  v-if="tableData.audit_state==3" class="title1" style="margin-left:20px">提示:已产生的费用将在2~3个工作日退回</div>
            </div>
            
          </div>
        <div class="submit" v-if="tableData.audit_state==3" @click="submit">点击重新申请</div>


        <div  v-if="tableData.express">
             <div class="auditInfo">物流信息:</div>
        <div class="loginBtn1">
          <div>{{tableData.express_info.Shipper}}<span>{{tableData.express_info.LogisticCode}}</span><span style="color:#4B93FF" @click="copyBtn">复制</span><div class="comfigBtn" @click="comfigBtn">确认收货</div></div>
        </div>
        <div class="steps1" style="color:#000" v-if="tableData.express.State==3">
            <div>
              <div style="margin-left:-10px" class="boxcolor3">收</div>
              <div class="linecolor1"></div>
            </div>
          <div class="neth">
            <!-- <div>已签收{{tableData.express.Traces[0].AcceptTime}}</div> -->
            <div>{{endData.express.Traces[0].AcceptStation}}</div>
          </div>
        </div>
        <div class="steps1"  v-for="(item,index) in tableData.express.Traces" v-bind:key="index">
            <div v-if="index!=0">
              <div class="boxcolor2"></div>
              <div class="linecolor1"></div>
            </div>
            <div v-else>
              <div class="boxcolor1"></div>
              <div class="linecolor1"></div>
            </div>
          <div :class="index==0&&item.Action!=3?'neth colorText':'neth'">
            <div v-if="item.Action==1">以揽件{{item.AcceptTime}}</div>
            <div v-if="item.Action==2||item.Action==201">运输中{{item.AcceptTime}}</div>
            <div v-if="item.Action==202">派送中{{item.AcceptTime}}</div>
            <div v-if="item.Action==211">待取件{{item.AcceptTime}}</div>
            <div v-if="item.Action==3">已签收{{item.AcceptTime}}</div>
            <div>{{item.AcceptStation}}</div>
          </div>
        </div>
        <div class="steps1" v-if="tableData.express_state==2">
            <div>
              <div :class="tableData.express.State==0?'boxcolor1':'boxcolor2'"></div>
              <div class="linecolor1"></div>
            </div>
          <div class="neth" :style="tableData.express.State==0?'color:#000':''">
            <div>已发货{{tableData.update_time}}</div>
            <div>等待揽收中</div>
          </div>
        </div>
        <div class="steps1">
            <div>
              <div class="boxcolor2"></div>
            </div>
          <div class="neth">
            <div>已下单{{tableData.pay_at}}</div>

          </div>
        </div>
        </div>
        <div v-else>
            <div class="auditInfo">物流信息:</div>
        <div class="cart">
          <img src="../../assets/cart.png"/>
        </div>
        </div>
         
       
        <!-- 提示框 -->
      <toast v-if="toastShow" :config="configData"></toast>
    </div>
</template>
<script>
import * as apiCommon from "@/api/common";
// import wx from "weixin-js-sdk";
import toast from "@/components/toast";

export default {
  data() {
    return {
      active: 1,
      queryList: null,
      tableData: {
        audit_info: {},
        express: {},
        express_info:{},
      },
      select: "",
      endData: [],
      configData: {
        title: ""
      },
      toastShow: false
    };
  },
  components: {
    toast
  },
  created() {
    this.queryList = this.$route.query;
    document.title = "流程状态";
    this.getDetails();
  },
  methods: {
    copyBtn() {
      this.$copyText("CG9412150").then(
        e => {
          this.configData.title = "复制成功";
          this.toastShow = true;

          console.log(e, this.toastShow);
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
        },
        function(e) {
          alert("Can not copy");
          console.log(e);
        }
      );
    },
    async comfigBtn() {
      let data = {
        express_state: 1
      };
      let res = await apiCommon.detailsInfo(data, this.queryList.id);
      if (res.code == 0) {
        this.toastShow = true;
        this.configData.title = "操作成功";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
      }
    },
    mineMessage() {
      console.log(1, "2");
      //       wx.config({
      //    debug: false,
      //    appId: '',
      //    timestamp: ,
      //    nonceStr: '',
      //    signature: '',
      //    jsApiList: [
      //      // 所有要调用的 API 都要加到这个列表中
      //      'getLocation',
      //      'hideOptionMenu',
      //      'closeWindow'
      //    ]
      //  });
      // wx.ready(function() {
      // wx.closeWindow();
      this.$router.push("../lador/service");
      // });
    },
    submit(index) {
      if(index==1){
          this.$router.push("./modification?id="+this.queryList.id);
      }else{
        this.$router.push("./agreement");
      }
      
    },
    async getDetails() {
      let res = await apiCommon.getDetails(this.queryList.id);
      console.log(res, "res");
      if (res.code == 0) {
        this.tableData = res.data;
        this.endData = res.data;
        if (this.tableData.express) {
          this.tableData.express.Traces.reverse();
        }
        if (res.data.audit_state == 1) {
          this.select = 1;
        } else if (res.data.express_state == 1 || res.data.express_state == 2) {
          this.select = 2;
        } else if (res.data.audit_state == 3) {
          this.select = 3;
        }
        if (res.data.audit_state == 2 && res.data.express_state == 3) {
          this.select = 3;
        }
        if (this.tableData.express) {
          if (this.tableData.express.State == 3) {
            this.tableData.express.Traces.splice(0, 1);
          }
        }
      }
    }
  }
};
</script>
<style scoped>
@step-icon-size {
  font-size: 40px;
}
.title_text{
  color:#4B93FF;
  margin:0px 0 0 20px!important;
  font-size:24px
}
.card{
  margin: 20px 0
}
.success2{
  width: 24px;
  height: 24px;
  margin: 10px 0 0 5px
}
.messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle1 {
  text-align: right;
  margin-right: 20px;
}
.loginBtn1 {
  margin: 20px 0;
}
.loginBtn1 > div {
  display: flex;
  margin-left: 77px;
}
.loginBtn1 span {
  margin: 0 5px;
}
.comfigBtn {
  width: 124px;
  height: 40px;
  background-color: #4b93ff;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  margin-left: 79px;
}
.getAddress {
  display: flex;
  margin-left: 70px;
  margin-top: 30px;
}
.steps1 {
  /* height: 200px; */
  display: flex;
  margin-left: 60px;
}
.steps1 .colorText {
  color: #000000;
}
.steps1 .neth div {
  margin: 5px 0;
}
.neth {
  padding: 5px 0 20px 10px;
}
.linecolor {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px dashed #ff5e5e;
  margin-left: 18.5px;
}
.linecolor1 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.linecolor2 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.boxcolor {
  width: 37px;
  height: 37px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  border-radius: 50%;
  opacity: 1;
  text-align: center;
  line-height: 37px;
  color: #ff5e5e;
  font-size: 22px;
}
.boxcolor1 {
  width: 13px;
  height: 13px;
  background: #ff5e5e;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor2 {
  width: 13px;
  height: 13px;
  background: #d1d1d1;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor3 {
  width: 44px;
  height: 44px;
  background: #fc6305;
  border-radius: 50%;
  opacity: 1;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
}
.cart {
  width: 120px;
  height: 120px;
  margin: 190px auto 0;
}
.cart img {
  width: 100%;
  height: 100%;
}
.auditInfo {
  margin: 50px 0 0 37px;
}
.content1 {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 40px;
  box-sizing: border-box;
  width: 100%
}
.steps {
  height: 150px;
  padding-left: 61px;
}
.step_text {
  font-size: 22px;
  margin: 52px 0 0 54px;
}
.van-step--vertical {
  font-size: 22px;
  color: #000000;
}
.van-step--vertical {
  /* height: 200px; */
  line-height: 45px;
}
.title1 {
  color: #ff5e5e;
}
.van-step__icon--active,
.van-step__icon--finish,
.van-step__title--active,
.van-step__title--finish {
  color: #ff5e5e;
}
.submit {
  width: 160px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #4b93ff;
  opacity: 1;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  margin-left: 100px;
  margin-top: 20px;
}
.logistics {
  margin-left: 70px;
}
</style>